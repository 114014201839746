import React, { useEffect, useState } from 'react';
import DataTable, { ExpanderComponentProps, TableColumn } from 'react-data-table-component';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import clsx from 'clsx';

import { useMerchants } from '../../../contexts/GraphQL';
import { PendingPaymentType } from '../../../types/grapql';
import useRfqs from '../../../hooks/useRfqs';
import { useMerchantInfo } from 'shared-components/providers/MerchantInfoProvider';
import { useAuthEID } from 'shared-components/providers/AuthEIDProvider';
import { useApp } from 'shared-components/providers/AppProvider';

import styles from './style.module.scss';

export const ExpandedComponent: React.FC<ExpanderComponentProps<PendingPaymentType>> = ({ data }) => {
  return (
    <>
      {data.kycData ? (
        <div className={styles.kycContent}>
          <Row>
            <Col xs={12} md={6}>
              <Row className={styles.kycField}>
                <Col xs={4} className={styles.label}>
                  Transaction ID
                </Col>
                <Col xs={8} className={styles.value}>
                  {data.operationId}
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={6}>
              <Row className={styles.kycField}>
                <Col xs={4} className={styles.label}>
                  Personal ID
                </Col>
                <Col xs={8} className={styles.value}>
                  {data.clientPid}
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={6}>
              <Row className={styles.kycField}>
                <Col xs={4} className={styles.label}>
                  Receiving IBAN
                </Col>
                <Col xs={8} className={styles.value}>
                  {data.iban}
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={6}>
              <Row className={styles.kycField}>
                <Col xs={4} className={styles.label}>
                  Sending IBAN
                </Col>
                <Col xs={8} className={styles.value}>
                  {data.clientIban}
                </Col>
              </Row>
            </Col>

            {Object.keys(data.kycData!)?.map((val, key) => {
              const value = (data.kycData as any)[val] ?? '';
              if (typeof value === 'object') {
                return (
                  <>
                    {Object.keys(value)?.map((val, key) => {
                      const intval = (value as any)[val] ?? '';
                      return (
                        <Col key={key} xs={12} md={6}>
                          <Row className={styles.kycField}>
                            <Col xs={4} className={styles.label}>
                              {val}
                            </Col>
                            <Col xs={8} className={styles.value}>
                              {intval}
                            </Col>
                          </Row>
                        </Col>
                      );
                    })}
                  </>
                );
              }
              return (
                <Col key={key} xs={12} md={6}>
                  <Row className={styles.kycField}>
                    <Col xs={4} className={styles.label}>
                      {val}
                    </Col>
                    <Col xs={8} className={styles.value}>
                      {value}
                    </Col>
                  </Row>
                </Col>
              );
            })}
          </Row>
        </div>
      ) : (
        <div />
      )}
      {data.depositData ? (
        <div className={styles.kycContent}>
          <Row>
            {Object.keys(data.depositData!)?.map((val, key) => {
              const value = (data.depositData as any)[val] ?? '';
              return (
                <Col key={key} xs={12} md={6}>
                  <Row className={styles.kycField}>
                    <Col xs={4} className={styles.label}>
                      {val}
                    </Col>
                    <Col xs={8} className={styles.value}>
                      {value}
                    </Col>
                  </Row>
                </Col>
              );
            })}
          </Row>
        </div>
      ) : (
        <div />
      )}
    </>
  );
};

const Rfq = () => {
  const { pid } = useParams();
  const {
    values: { searchBox },
    setters: { setSearchBox }
  } = useApp();

  const { data, fetchList } = useRfqs();
  const { name } = useMerchantInfo();
  const { merchants } = useMerchants();
  const { isAdmin } = useAuthEID();
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(30);
  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    setSearchBox('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handlePageChange(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handlePageChange(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [perPage, searchBox]);

  useEffect(() => {
    setTotalRows(data.totalCount);
    setLoading(false);
  }, [data]);

  const handlePageChange = (page: number) => {
    setLoading(true);
    const totalPages = Math.ceil(data.totalCount / perPage);
    if (page === 1) {
      fetchList({ txRef: searchBox, pid, first: perPage });
    } else if (page >= totalPages) {
      fetchList({ txRef: searchBox, pid, last: perPage });
    } else if (page > currentPage) {
      fetchList({ txRef: searchBox, pid, first: perPage, after: data.pageInfo.endCursor });
    } else if (page < currentPage) {
      fetchList({ txRef: searchBox, pid, last: perPage, before: data.pageInfo.startCursor });
    } else {
      fetchList({ txRef: searchBox, pid, first: perPage, after: data.pageInfo.endCursor });
    }
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage: number, _: any) => {
    setPerPage(newPerPage);
  };

  const columns: TableColumn<PendingPaymentType>[] = [
    {
      name: 'ID',
      cell: (row) => {
        if (row.id) {
          return atob(row.id).split(':').at(1) || '';
        }
        return '';
      },
      wrap: true,
      width: '80px'
    },
    {
      name: 'TX ref',
      selector: (row) => row.txRef ?? '',
      wrap: true
    },
    {
      name: 'Company name',
      selector: (row) => {
        return isAdmin ? merchants?.find((merchant) => merchant.email === row.email)?.name || '' : name;
      },
      wrap: true,
      minWidth: '150px'
    },
    {
      name: 'Status',
      selector: (row) => row.status ?? '',
      wrap: true,
      width: '100px'
    },
    {
      name: 'KYC fee',
      selector: (row) => row.kycFee,
      wrap: true,
      width: '93px'
    },
    {
      name: 'SEK deposit',
      selector: (row) => row.sekDeposit ?? '', //TODO: replace by eurRequest
      wrap: true,
      width: '120px'
    },
    {
      name: 'Created at',
      selector: (row) => row.createdAt ?? '',
      width: '180px'
    },
    {
      name: 'Settled at',
      selector: (row) => row.settledAt ?? '',
      width: '180px'
    },
    {
      name: 'Live',
      selector: (row) => row.live ?? '',
      width: '180px'
    }
  ];

  useEffect(() => {
    handlePageChange(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);
  return (
    <>
      <DataTable
        highlightOnHover={true}
        columns={columns}
        data={data.data ?? []}
        progressPending={loading}
        pagination
        paginationServer
        paginationPerPage={30}
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        customStyles={{
          headCells: {
            style: {
              backgroundColor: 'lightgray',
              textTransform: 'uppercase'
            }
          }
        }}
        className={clsx(styles.table, 'shadow p-3 mb-5 bg-white overflow-auto')}
        expandOnRowClicked
        expandableRows
        expandableRowsComponent={ExpandedComponent}
        expandableInheritConditionalStyles
      />
    </>
  );
};
export default Rfq;
